import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../components/Layout';
import Container from '@material-ui/core/Container';
import DemoComponent from '../components/Demo';

const useStyles = makeStyles(theme => ({
  demoContent: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8),
    },
  },
}));

export default function Demo() {
  const classes = useStyles();

  return (
    <Layout>
      <Container maxWidth="lg" component="main" className={classes.demoContent}>
        <DemoComponent />
      </Container>
    </Layout>
  );
}
